<template>
  <a-form-model-item :prop="prop" :colon="false">
    <span slot="help" class="text-dark"></span>
    <span slot="label" class="text-dark">{{ label || $t(prop) }}</span>
    <slot></slot>
  </a-form-model-item>
</template>

<script>
export default {
  name: 'JField',
  props: [
    'prop',
    'label',
  ],
}
</script>
