<template>
  <j-abm
    name="user"
    :suppliers="suppliers"
    :features="{
      search: false,
      create: true,
      update: true,
      delete: true,
    }"
    @create="onCreate"
    @update="onUpdate"
    @delete="onDelete">
    <template #form="{item}">
      <div class="col-xl-5 col-lg-12 mb-2">
        <h5>{{ $t('form') }}</h5>
      </div>
      <div class="col-xl-7 col-lg-12">
        <a-form-model
            ref="form"
            :model="item"
            :rules="rules">
          <!-- Email -->
          <j-input
            prop="email"
            v-model="item.email"
            icon="mail" />
          <!-- Roles -->
          <j-field
            prop="roles">
            <a-checkbox-group
              v-model="item.roles"
              :options="roles" />
          </j-field>
        </a-form-model>
      </div>
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import v from '@/utils/validators'
import f from '@/utils/formatters'
import JAbm from '@/views/shared/abm/update'
import JInput from '@/views/shared/forms/input'
import JField from '@/views/shared/forms/field'

const roles = [
  'PICKER',
  'DISPATCHER',
  'DROPPER',
]

export default {
  components: {
    JAbm,
    JInput,
    JField,
  },
  data: function () {
    return {
      f,
      roles,
      rules: {
        type: [v.required],
        email: [v.required, v.email],
        subject: [v.number],
        roles: [v.required],
      },
    }
  },
  computed: {
    suppliers() {
      return {
        fetchOne: id => lms.user.fetchOne(id),
        payload: () => {
          return {
            type: 'OPERATOR',
          }
        },
      }
    },
  },
  methods: {
    onCreate(user) {
      this.onSubmit(user, user => lms.user.create(user))
    },
    onUpdate(user) {
      this.onSubmit(user, user => lms.user.update(user))
    },
    onDelete(user) {
      lms.user.remove(user).then(_ => this.$router.push('/users'))
    },
    onSubmit(user, callback) {
      Promise.all([
        new Promise((resolve, reject) => this.$refs.form.validate(resolve)),
      ]).then(results => {
        if (results.every(Boolean)) {
          callback(user).then(_ => this.$router.push('/users'))
        }
      })
    },
  },
}
</script>
