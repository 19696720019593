var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('j-abm',{attrs:{"name":"user","suppliers":_vm.suppliers,"features":{
    search: false,
    create: true,
    update: true,
    delete: true,
  }},on:{"create":_vm.onCreate,"update":_vm.onUpdate,"delete":_vm.onDelete},scopedSlots:_vm._u([{key:"form",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"col-xl-5 col-lg-12 mb-2"},[_c('h5',[_vm._v(_vm._s(_vm.$t('form')))])]),_c('div',{staticClass:"col-xl-7 col-lg-12"},[_c('a-form-model',{ref:"form",attrs:{"model":item,"rules":_vm.rules}},[_c('j-input',{attrs:{"prop":"email","icon":"mail"},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}}),_c('j-field',{attrs:{"prop":"roles"}},[_c('a-checkbox-group',{attrs:{"options":_vm.roles},model:{value:(item.roles),callback:function ($$v) {_vm.$set(item, "roles", $$v)},expression:"item.roles"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }